@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700;800&display=swap");

.font400 {
  font-weight: 400 !important;
  font-family: "Mulish" !important;
}

.font500 {
  font-weight: 500 !important;
  font-family: "Mulish" !important;
}

.font600 {
  font-weight: 600 !important;
  font-family: "Mulish" !important;
}

.font700 {
  font-weight: 700 !important;
  font-family: "Mulish" !important;
}

.font800 {
  font-weight: 800 !important;
  font-family: "Mulish" !important;
}

.imageSize {
  height: 100%;
  width: 100%;
}

.primary {
  color: rgba(209, 46, 41, 1);
}

.flexrow {
  display: flex;
  flex-direction: row;
}

.flexcol {
  display: flex;
  flex-direction: column;
}

.flexcenter {
  display: flex;
  justify-content: center;
  text-align: center;
}

.flexstart {
  display: flex;
  justify-content: start;
}

.preLine {
  white-space: pre-line;
}

.container {
  width: 1199px;
  margin-left: auto;
  margin-right: auto;
}
.background-container {
  width: 175%;
  height: 133vh;
  border-radius: 100%;
  background: linear-gradient(90deg, #fdfcfb 0%, #e2d1c3 100%);
  /* margin-right: auto; */
  margin-left: auto;
  transform: rotate(133.299deg);
}

.activeServiceTab {
  background: #d12e29;
  border-radius: 4px;
  padding: 15px 10px;
  width: 190px;
  max-width: 190px !important;
  color: #fff !important;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  white-space: nowrap !important;
  overflow: hidden !important;
  height: 48px;
  text-overflow: ellipsis !important;
  text-align: center;
}

.serviceTab {
  background: #fff;
  border-radius: 4px;
  width: 190px;
  padding: 15px 10px;
  max-width: 190px !important;
  color: #d12e29 !important;
  font-size: 14px;
  line-height: 18px !important;
  border: 1px solid #d12e29;
  cursor: pointer;
  white-space: nowrap !important;
  overflow: hidden !important;
  height: 48px;
  text-overflow: ellipsis !important;
  text-align: center;
  max-height: 48px !important;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .container {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1023px) {
  .container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 900px;
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 480px;
  }
}

.noDecoration {
  text-decoration: none;
}

/* Navbar */

.hamburgerIcon {
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 13px;
  height: 24px;
  width: 24px;
}

.menu {
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  margin-top: 36px;
  background-color: #fff;
  top: 20px;
  height: 100vh;
  width: 100%;
  transition: all 0.3s ease-out;
  animation-name: menu;
  animation-duration: 1s;
  overflow: hidden;
}

@keyframes menu {
  0% {
    transform: translate(100%);
  }

  100% {
    transform: translate(0%);
  }
}

@keyframes close {
  0% {
    transform: translate(100%);
  }

  100% {
    transform: translate(0%);
  }
}

/* homepage */
.linkstyle {
  cursor: pointer;
  text-decoration: underline;
}

.faqAns {
  /* opacity: 0; */
  max-height: 0;
  overflow: hidden;
  transition: all 01s ease;
}

.faqClose {
  max-height: 0px;
  opacity: 0;
}

.faqAns .faqOpen {
  max-height: 1000px;
  opacity: 1;
  /* animation-name: example;
  animation-duration: 4s; */
}

@keyframes example {
  0% {
    max-height: 0px;
    /* opacity: 0; */
  }

  100% {
    max-height: 1000px;
    /* opacity : 1 */
  }
}
.navP {
  font-size: 16px;
}
.navGap {
  column-gap: 32px;
}
@media (max-width: 1200px) {
  .navP {
    font-size: 14px !important;
  }
  .navGap {
    column-gap: 18px;
  }
}

/* .downArrow{
animation-name: arrow;
animation-duration: 4s;
animation-iteration-count:infinite;
animation-timing-function: linear;
}
@keyframes arrow {
  0%   {transform : translateY(0px)};
  25%  {transform : translateY(30px)};
  50%  {transform : translateY(50px)};
  75%  {transform : translateY(30px)};
  100% {transform : translateY(0px)};
} */

/* Swiper CSS */
/* Swiper Pagination */
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #d12e29 !important;
}

span.swiper-pagination-bullet {
  background-color: transparent;
  border: 0.5px solid #d12e29;
}

.myServiceSwiper > .swiper-pagination {
  display: none !important;
}

.myServiceListSwiper > .swiper-pagination {
  display: none !important;
}

/* Swiper navigation button */
.swiper-button-disabled {
  display: none !important;
}

.swiper-button-prev {
  color: #d12e29 !important;
  top: 60% !important;
  transform: translateY(-50%) !important;
  left: 0 !important;
}

.swiper-button-next {
  color: #d12e29 !important;
  top: 60% !important;
  transform: translateY(-50%) !important;
  right: 0 !important;
}

.myServiceSwiper > .swiper-button-prev {
  color: #d12e29 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  left: 0 !important;
  height: 100%;
  background-color: #fff;
  box-shadow: 20px 20px 50px #e8e8e8;
}

.myServiceSwiper > .swiper-button-next {
  color: #d12e29 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  right: 0 !important;
  height: 100%;
  background-color: #fff;
  box-shadow: 20px 20px 50px #e8e8e8;
}
.myServiceSwiper > .swiper-button-prev::after {
  font-size: 32px !important;
}
.myServiceSwiper > .swiper-button-next::after {
  font-size: 32px !important;
}

.myServiceListSwiper > .swiper-button-next {
  height: 100% !important;
  color: #272828 !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  top: 95% !important;
  background-color: #ffff;
  box-shadow: 20px 20px 50px #e8e8e8;
  font-size: 24px !important;
}

.myServiceListSwiper > .swiper-button-prev {
  height: 100% !important;
  color: #272828 !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  top: 95% !important;
  background-color: #ffff;
  box-shadow: 20px 20px 50px #e8e8e8;
  font-size: 24px !important;
}
.myServiceListSwiper > .swiper-button-prev::after {
  font-size: 24px !important;
}
.myServiceListSwiper > .swiper-button-next::after {
  font-size: 24px !important;
}
@media (max-width: 767px) {
  .myServiceSwiper > .swiper-pagination {
    margin-top: 10% !important;
    display: block !important;
  }

  .myReviewSwiper > .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
}

/* typography  */
.p1 {
  font-size: 20px !important;
  line-height: 140% !important;
  letter-spacing: 0.32px !important;
  color: #6c6c6c !important;
}

.p2 {
  font-size: 18px !important;
  line-height: 140% !important;
  letter-spacing: 0.32px !important;
  color: #6c6c6c;
}

.p3 {
  font-size: 16px !important;
  line-height: 140% !important;
  letter-spacing: 0.32px !important;
  color: #6c6c6c !important;
}

.p4 {
  font-size: 14px !important;
  line-height: 140% !important;
  letter-spacing: 0.32px !important;
  color: #6c6c6c !important;
}

.bannerHeading {
  font-size: 48px !important;
  line-height: 1.25 !important;
  color: #272828 !important;
}

.heading {
  font-size: 32px !important;
  line-height: 1.25 !important;
  color: #272828 !important;
}

.subHeading {
  font-size: 24px !important;
  line-height: 1.5 !important;
  color: #272828 !important;
}

@media (max-width: 1200px) {
  .p1 {
    font-size: 18px !important;
  }

  .p2 {
    font-size: 16px !important;
  }

  .p3 {
    font-size: 14px !important;
  }

  .p4 {
    font-size: 12px !important;
  }

  .bannerHeading {
    font-size: 42px !important;
  }

  .heading {
    font-size: 32px !important;
  }

  .subHeading {
    font-size: 20px !important;
  }
}

@media (max-width: 1023px) {
  .p1 {
    font-size: 16px !important;
  }

  .p2 {
    font-size: 14px !important;
  }

  .p3 {
    font-size: 12px !important;
  }

  .p4 {
    font-size: 12px !important;
  }

  .bannerHeading {
    font-size: 32px !important;
  }

  .heading {
    font-size: 28px !important;
  }

  .subHeading {
    font-size: 20px !important;
  }
}

@media (max-width: 767px) {
  .p1 {
    font-size: 18px !important;
  }

  .p2 {
    font-size: 16px !important;
  }

  .p3 {
    font-size: 14px !important;
  }

  .p4 {
    font-size: 12px !important;
  }

  .bannerHeading {
    font-size: 32px !important;
  }

  .heading {
    font-size: 24px !important;
  }

  .subHeading {
    font-size: 18px !important;
  }
}

.error-message {
  font-size: 12px;
  text-align: left;
  width: 100%;
  margin-top: 8px;
  color: red;
}

.css-55wkvy-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 0 !important;
  outline: none !important;
}
.css-55wkvy-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 0 !important;
  outline: none !important;
}
.css-ticdql-MuiInputBase-root-MuiInput-root:before {
  border: 0 !important;
}
.css-ticdql-MuiInputBase-root-MuiInput-root:after {
  border: 0 !important;
}
.css-1040onb-MuiInputBase-root-MuiInput-root:before {
  border: none !important;
}
.css-1040onb-MuiInputBase-root-MuiInput-root:after {
  border: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#quill-description > h1,
h2,
h3 {
  color: black;
  line-height: 36px;
}

/* container-banner */

.container-banner {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1200px) {
  .container-banner {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1440px) {
  .container-banner {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1023px) {
  .container-banner {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    /* padding-left: 20px;
    padding-right: 20px;
    max-width: 900px; */
  }
}

@media (max-width: 767px) {
  .container-banner {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    /* padding-left: 20px;
    padding-right: 20px;
    max-width: 480px; */
  }
}
